import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { useGetResourcesId } from "../../../../.rest-hooks/resources";
import type { ResourceApplianceLocation } from "../../../../.rest-hooks/types/resources.yml/resourceApplianceLocation";
import { REFETCH_INTERVAL_FREQUENT } from "../../../config/refetch";
import { AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL } from "../AVLPositions/constants";
import useLoadAVLSymbology from "../AVLPositions/useLoadAVLSymbology";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { APPLIANCE_POSITIONS_SOURCE_ID } from "./constants";

interface UseAppliancePositionMapDataParams {
  accessToken: string;
  resourceId: string;
}

const useAppliancePositionMapData = ({
  accessToken,
  resourceId,
}: UseAppliancePositionMapDataParams) => {
  const map = useMapContext();

  const { data: resourceData } = useGetResourcesId(resourceId, {
    query: {
      refetchInterval: REFETCH_INTERVAL_FREQUENT,
    },
  });

  const resource = resourceData?.data.data;
  let currentLocation: ResourceApplianceLocation | undefined;
  if (resource?.type === "appliance") {
    currentLocation = resource?.attributes.currentLocation;
  }

  useLoadAVLSymbology({
    accessToken,
    avlType: "appliance",
    map,
    layerId: resourceId,
    symbologyUrl: AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL,
  });

  useEffect(() => {
    if (!currentLocation) {
      return;
    }

    const data: FeatureCollection = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: currentLocation.location.coordinates,
          },
          properties: {
            StatusCategory: currentLocation.statusCategory,
            DirectionHor: currentLocation.directionHor,
          },
        },
      ],
    };

    const source = map.getSource(APPLIANCE_POSITIONS_SOURCE_ID);

    if (isGeoJsonSource(source)) {
      const [lng, lat] = currentLocation.location.coordinates;
      source.setData({
        ...data,
        features: data.features.map((feature) => ({
          ...feature,
          // NOTE: We add the avlType property to handle cases where appliance and portable icons have overlapping StatusCategory values
          properties: { ...feature.properties, avlType: "appliance" },
        })),
      });
      map.setCenter({
        lat,
        lng,
      });
      map.setZoom(10);
    }
  }, [currentLocation, map]);
};

export default useAppliancePositionMapData;
