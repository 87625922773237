import type { MapImage } from "../utils/loadImage";

export const avlTypes = ["appliance", "portable"] as const;

export type AVLType = (typeof avlTypes)[number];

export interface FeatureServerJson {
  drawingInfo: {
    renderer: {
      uniqueValueInfos: {
        symbol: { contentType: string; imageData: string };
        value: string;
      }[];
    };
  };
}

export const getFeatureServerSymbology = (
  featureServerJson: FeatureServerJson,
  overrides?: Partial<Record<string, string>>,
): MapImage[] =>
  featureServerJson.drawingInfo.renderer.uniqueValueInfos.map(
    (info: {
      symbol: { contentType: string; imageData: string };
      value: string;
    }) => ({
      src:
        overrides?.[info.value] ??
        `data:${info.symbol.contentType};base64,${info.symbol.imageData}`,
      imageId: info.value,
    }),
  );
