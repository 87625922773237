import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import useAppliancePositionMapData from "./useAppliancePositionMapData";
import useAppliancePositionsMapLayer from "./useAppliancePositionsMapLayer";

interface AppliancePositionLayerProps {
  resourceId: string;
}

const AppliancePositionLayer = ({
  resourceId,
}: AppliancePositionLayerProps) => {
  const accessToken = useAuthAccessToken();

  if (!accessToken) {
    // Should never happen -- all requests need this
    throw new Error("Unable to locate access token");
  }

  useAppliancePositionsMapLayer({
    accessToken,
  });
  useAppliancePositionMapData({ accessToken, resourceId });

  return null;
};

export default AppliancePositionLayer;
