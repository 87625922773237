import addLeadingZeros from "../utils/addLeadingZeros";
import getDecimal from "../utils/getDecimal";

const getHours = (value: string) => {
  const hours = value.split(".")[0];
  return Number(hours);
};

const getMinutes = (value: string) => {
  const minutes = getDecimal(Number(value));
  return Math.abs((minutes * 60) / 1000000);
};

const getSeconds = (minutes: number) => {
  const seconds = getDecimal(minutes);
  return Math.round((seconds * 60) / 100000);
};

type CoordinateType = "latitude" | "longitude";

const getCardinalityPrefix = (
  coordinateType: CoordinateType,
  hours: number,
) => {
  if (coordinateType === "latitude" && hours < 0) {
    return "S";
  }
  if (coordinateType === "latitude" && hours >= 0) {
    return "N";
  }
  if (coordinateType === "longitude" && hours >= 0) {
    return "E";
  }
  if (coordinateType === "longitude" && hours < 0) {
    return "W";
  }
};
interface DMSConversionProps {
  value: string;
  coordinateType: CoordinateType;
}

const getDMSConversion = ({ value, coordinateType }: DMSConversionProps) => {
  const hours = getHours(value);
  const minutes = getMinutes(value);
  const seconds = getSeconds(minutes);

  const hoursDisplayValue = addLeadingZeros({
    addLength: 3,
    value: hours,
  });
  const minutesDisplayValue = addLeadingZeros({
    addLength: 2,
    value: Math.floor(minutes),
  });
  const secondsDisplayValue = addLeadingZeros({ addLength: 2, value: seconds });

  const cardinalityPrefix = getCardinalityPrefix(coordinateType, hours);
  return `${hoursDisplayValue}°${cardinalityPrefix} ${minutesDisplayValue}′${secondsDisplayValue}″`;
};

export default getDMSConversion;
