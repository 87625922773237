import type { ResourceApplianceAssignment } from "../../../../.rest-hooks/types/resources.yml/resourceApplianceAssignment";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";

interface ApplianceAssignmentDateRangeProps {
  assignment: ResourceApplianceAssignment;
}

const ApplianceAssignmentDateRange = ({
  assignment,
}: ApplianceAssignmentDateRangeProps) => {
  if (!assignment.assignedTo) {
    return (
      <>Start: {getFormattedDateAndTime(assignment.assignedFrom * 1000)}</>
    );
  }

  return (
    <>
      Start: {getFormattedDateAndTime(assignment.assignedFrom * 1000)} <br />
      End: {getFormattedDateAndTime(assignment.assignedTo * 1000)}
    </>
  );
};

export default ApplianceAssignmentDateRange;
