import { Tab, TabList, TabPanel, Tabs } from "@app/design-system";
import styled from "styled-components";
import type { ResourceAircraft } from "../../../../.rest-hooks/types/resources.yml/resourceAircraft";
import AircraftActivitySummary from "./AircraftActivitySummary";
import AircraftSummary from "./AircraftSummary";

const StyledTabList = styled(TabList)`
  margin: 0 auto 16px -4px;
`;

interface AircraftDetailProps {
  aircraft: ResourceAircraft;
}

const AircraftDetail = ({ aircraft }: AircraftDetailProps) => {
  return (
    <Tabs>
      <StyledTabList>
        <Tab>Activity</Tab>
        <Tab>Aircraft</Tab>
      </StyledTabList>
      <TabPanel>
        <AircraftActivitySummary aircraft={aircraft} />
      </TabPanel>
      <TabPanel>
        <AircraftSummary aircraft={aircraft} />
      </TabPanel>
    </Tabs>
  );
};

export default AircraftDetail;
