import type { FormControlOption } from "@app/design-system";
import { ResourceHeavyPlantCategory } from "../../.rest-hooks/types/resources.yml/resourceHeavyPlantCategory";

export const heavyPlantCategories = Object.values(ResourceHeavyPlantCategory);

export const heavyPlantCategoryLabels: Record<
  ResourceHeavyPlantCategory,
  string
> = {
  "bulldozer-d5": "Bulldozer D5",
  "bulldozer-d6": "Bulldozer D6",
  "grader-12": "Grader 12",
  "grader-14": "Grader 14",
  unknown: "Unknown",
};

export const heavyPlantCategoryColors: Record<
  ResourceHeavyPlantCategory,
  string
> = {
  "bulldozer-d5": "#DB7A3D",
  "bulldozer-d6": "#F38744",
  "grader-12": "#F49357",
  "grader-14": "#F59F69",
  unknown: "#F7AB7C",
};

export const isHeavyPlantCategory = (
  heavyPlantCategory: string,
): heavyPlantCategory is ResourceHeavyPlantCategory => {
  return heavyPlantCategories.includes(
    heavyPlantCategory as ResourceHeavyPlantCategory,
  );
};

export const formatHeavyPlantCategory = (
  heavyPlantCategory: ResourceHeavyPlantCategory | undefined,
): string => {
  if (!heavyPlantCategory) return "—";

  if (!isHeavyPlantCategory(heavyPlantCategory)) {
    return heavyPlantCategory;
  }

  return heavyPlantCategoryLabels[heavyPlantCategory];
};

export const heavyPlantCategoryOptions: FormControlOption<ResourceHeavyPlantCategory>[] =
  heavyPlantCategories.map((category) => ({
    label: formatHeavyPlantCategory(category),
    value: category,
  }));
