/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Statuses a heavy plant resource may be in.

* `available` - `APPROVED` in ARENA.
* `dispatched` - `APPROVED` in ARENA and assigned to an incident.
* `undispatchable` - `SUSPENDED` in ARENA.
* `unknown` - Fallback for missing statuses.

 */
export type ResourceHeavyPlantStatus =
  (typeof ResourceHeavyPlantStatus)[keyof typeof ResourceHeavyPlantStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceHeavyPlantStatus = {
  available: "available",
  dispatched: "dispatched",
  undispatchable: "undispatchable",
  unknown: "unknown",
} as const;
