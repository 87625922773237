import type { ResourceApplianceGpsStatus } from "../../.rest-hooks/types/resources.yml/resourceApplianceGpsStatus";

export const applianceGpsStatusLabels: Record<
  ResourceApplianceGpsStatus,
  string
> = {
  "gps-device-error": "GPS device error",
  "gps-position-fix": "GPS position fix",
  "insufficient-satellites": "Insufficient satellites",
  "not-known": "Unknown",
};
