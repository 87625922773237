import { FieldGrid } from "@app/design-system";
import styled from "styled-components";
import type { ResourceAircraft } from "../../../../.rest-hooks/types/resources.yml/resourceAircraft";
import formatCoordinates from "../../../config/formatCoordinates";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import AircraftPositionMap from "../AircraftPositionMap/AircraftPositionMap";

const StyledAircraftCurrentLocation = styled.div`
  display: grid;
  gap: 12px;
`;

interface AircraftCurrentLocationProps {
  aircraft: ResourceAircraft;
}

const AircraftCurrentLocation = ({
  aircraft,
}: AircraftCurrentLocationProps) => {
  return (
    <StyledAircraftCurrentLocation>
      {aircraft.attributes.currentLocation && (
        <AircraftPositionMap resourceId={aircraft.id} />
      )}
      <FieldGrid>
        {aircraft.attributes.currentLocation && (
          <FieldGrid.Item label="Transmitted at:">
            {aircraft.attributes.currentLocation?.transmittedAt ? (
              getFormattedDateAndTime(
                aircraft.attributes.currentLocation.transmittedAt * 1000,
              )
            ) : (
              <FallbackElement />
            )}
          </FieldGrid.Item>
        )}
        <FieldGrid.Item label="Current location:">
          {formatCoordinates(
            aircraft.attributes.currentLocation?.location?.coordinates,
          )}
        </FieldGrid.Item>
        {aircraft.attributes.assignment && (
          <FieldGrid.Item label="Staging location:">
            {formatCoordinates(
              aircraft.attributes.assignment.stagingLocationCoordinates
                ?.coordinates,
            )}
          </FieldGrid.Item>
        )}
      </FieldGrid>
    </StyledAircraftCurrentLocation>
  );
};

export default AircraftCurrentLocation;
