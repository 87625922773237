import type { ResourceAppliance } from "../../../../.rest-hooks/types/resources.yml/resourceAppliance";
import ApplianceActivitySummary from "./ApplianceActivitySummary";

export interface ApplianceDetailProps {
  appliance: ResourceAppliance;
}

const ApplianceDetail = ({ appliance }: ApplianceDetailProps) => {
  return <ApplianceActivitySummary appliance={appliance} />;
};

export default ApplianceDetail;
