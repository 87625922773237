/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * The category of aircraft
 */
export type ResourceAircraftCategory =
  (typeof ResourceAircraftCategory)[keyof typeof ResourceAircraftCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceAircraftCategory = {
  "rw-type-1-heavy": "rw-type-1-heavy",
  "rw-type-2-medium": "rw-type-2-medium",
  "rw-type-3-light": "rw-type-3-light",
  "rw-type-4-extra-light": "rw-type-4-extra-light",
  "rw-other": "rw-other",
  "fw-type-1-meat": "fw-type-1-meat",
  "fw-type-2-meat": "fw-type-2-meat",
  "fw-type-3-meat": "fw-type-3-meat",
  "fw-type-4-seat": "fw-type-4-seat",
  "fw-type-5-seat": "fw-type-5-seat",
  "fw-aas-recce": "fw-aas-recce",
  "fw-other": "fw-other",
  rpas: "rpas",
  "unmanned-aerial-vehicle": "unmanned-aerial-vehicle",
  other: "other",
  unspecified: "unspecified",
} as const;
