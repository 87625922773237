import useAircraftPositionMapData from "./useAircraftPositionMapData";
import useAircraftPositionMapLayer from "./useAircraftPositionMapLayer";

interface AircraftPositionLayerProps {
  resourceId: string;
}

const AircraftPositionLayer = ({ resourceId }: AircraftPositionLayerProps) => {
  useAircraftPositionMapLayer();
  useAircraftPositionMapData({ resourceId });

  return null;
};

export default AircraftPositionLayer;
