import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { useGetResourcesId } from "../../../../.rest-hooks/resources";
import type { ResourceAircraftCategory } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftCategory";
import type { ResourceAircraftLocation } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftLocation";
import type { ResourceAircraftStatus } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftStatus";
import getAircraftResourceCategoryStatus from "../../../config/getAircraftResourceCategoryStatus";
import { REFETCH_INTERVAL_FREQUENT } from "../../../config/refetch";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { AIRCRAFT_POSITION_SOURCE_ID } from "./constants";

interface UseAircraftPositionMapDataParams {
  resourceId: string;
}

const useAircraftPositionMapData = ({
  resourceId,
}: UseAircraftPositionMapDataParams) => {
  const map = useMapContext();

  const { data: resourceData } = useGetResourcesId(resourceId, {
    query: {
      refetchInterval: REFETCH_INTERVAL_FREQUENT,
    },
  });

  const resource = resourceData?.data.data;
  let currentLocation: ResourceAircraftLocation | undefined;
  if (resource?.type === "aircraft") {
    currentLocation = resource?.attributes.currentLocation;
  }

  useEffect(() => {
    if (!currentLocation || !resource) {
      return;
    }

    const data: FeatureCollection = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: currentLocation.location.coordinates,
          },
          properties: {
            categoryStatus: getAircraftResourceCategoryStatus(
              resource.attributes.category as ResourceAircraftCategory,
              resource.attributes.status as ResourceAircraftStatus,
            ),
          },
        },
      ],
    };

    const source = map.getSource(AIRCRAFT_POSITION_SOURCE_ID);

    if (isGeoJsonSource(source)) {
      const [lng, lat] = currentLocation.location.coordinates;
      source.setData(data);
      map.setCenter({
        lat,
        lng,
      });
      map.setZoom(10);
    }
  }, [currentLocation, map, resource]);
};

export default useAircraftPositionMapData;
