import type { FormControlOption } from "@app/design-system";
import { ResourceAircraftCategory } from "../../.rest-hooks/types/resources.yml/resourceAircraftCategory";

export const aircraftCategories = Object.values(ResourceAircraftCategory);

export const aircraftCategoryLabels: Record<ResourceAircraftCategory, string> =
  {
    "fw-aas-recce": "FW - AAS / Recce",
    "fw-other": "FW - Other",
    "fw-type-1-meat": "FW - Type 1 MEAT",
    "fw-type-2-meat": "FW - Type 2 MEAT",
    "fw-type-3-meat": "FW - Type 3 MEAT",
    "fw-type-4-seat": "FW - Type 4 SEAT",
    "fw-type-5-seat": "FW - Type 5 SEAT",
    "rw-other": "RW - Other",
    "rw-type-1-heavy": "RW - Type 1 Heavy",
    "rw-type-2-medium": "RW - Type 2 Medium",
    "rw-type-3-light": "RW - Type 3 Light",
    "rw-type-4-extra-light": "RW - Type 4 Extra Light",
    "unmanned-aerial-vehicle": "Unmanned Aerial Vehicle",
    other: "Other",
    rpas: "RPAS",
    unspecified: "Unspecified",
  };

/**
 * These labels are slightly different to what we display elsewhere in the app
 * in order to show an alias that these users are more familiar with for
 * certain types of aircraft.
 *
 * @see https://firetrail.atlassian.net/browse/RRE-91
 */
export const aircraftCategoryResponseLabels: Record<
  ResourceAircraftCategory,
  string
> = {
  ...aircraftCategoryLabels,
  "fw-type-1-meat": "FW - LAT",
  "fw-type-4-seat": "FW - SEAT",
};

export const aircraftCategoryShortLabels: Record<
  ResourceAircraftCategory,
  string
> = {
  "fw-aas-recce": "FW AAS/Recce",
  "fw-other": "FW - Other",
  "fw-type-1-meat": "FW T1 MEAT",
  "fw-type-2-meat": "FW T2 MEAT",
  "fw-type-3-meat": "FW T3 MEAT",
  "fw-type-4-seat": "FW T4 SEAT",
  "fw-type-5-seat": "FW T5 SEAT",
  "rw-other": "RW - Other",
  "rw-type-1-heavy": "RW T1 Heavy",
  "rw-type-2-medium": "RW T2 Medium",
  "rw-type-3-light": "RW T3 Light",
  "rw-type-4-extra-light": "RW T4 Extra Light",
  "unmanned-aerial-vehicle": "UAV",
  other: "Other",
  rpas: "RPAS",
  unspecified: "Unspecified",
};

export const aircraftCategoryColors: Record<ResourceAircraftCategory, string> =
  {
    "fw-aas-recce": "#F1A8D3",
    "fw-other": "#F5BEDE",
    "fw-type-1-meat": "#E03B9B",
    "fw-type-2-meat": "#E451A6",
    "fw-type-3-meat": "#E766B1",
    "fw-type-4-seat": "#EB7CBC",
    "fw-type-5-seat": "#EE92C8",
    "rw-other": "#DD2590",
    "rw-type-1-heavy": "#7F2359",
    "rw-type-2-medium": "#922866",
    "rw-type-3-light": "#A42E73",
    "rw-type-4-extra-light": "#C72182",
    "unmanned-aerial-vehicle": "#FCE9F4",
    other: "#FCE9F4",
    rpas: "#F8D3E9",
    unspecified: "#FCE9F4",
  };

export const isAircraftCategory = (
  aircraftCategory: string,
): aircraftCategory is ResourceAircraftCategory => {
  return aircraftCategories.includes(
    aircraftCategory as ResourceAircraftCategory,
  );
};

export const formatAircraftCategory = (
  aircraftCategory: ResourceAircraftCategory | undefined,
): string => {
  if (!aircraftCategory) return "—";

  if (isAircraftCategory(aircraftCategory)) {
    return aircraftCategoryLabels[aircraftCategory];
  }

  return aircraftCategory;
};

export const aircraftCategoryOptions: FormControlOption<ResourceAircraftCategory>[] =
  aircraftCategories.map((category) => ({
    label: aircraftCategoryLabels[category],
    value: category,
  }));
