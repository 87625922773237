import type { FormControlOption } from "@app/design-system";
import { ResourceApplianceCategory } from "../../.rest-hooks/types/resources.yml/resourceApplianceCategory";

export const applianceCategories = Object.values(ResourceApplianceCategory);

export const applianceCategoryGroups = [
  "cat-1-4",
  "cat-5",
  "cat-6",
  "cat-7-8",
  "cat-9",
  "cat-10-11",
  "cat-12",
  "cat-13",
  "cat-14",
  "cat-15",
  "cat-16-19",
  "cat-20",
  "cat-21",
  "cat-22",
  "unknown",
] as const;

export type ApplianceCategoryGroup = (typeof applianceCategoryGroups)[number];

export const applianceCategoryGroupMap: Record<
  ResourceApplianceCategory,
  ApplianceCategoryGroup
> = {
  "category-1": "cat-1-4",
  "category-2": "cat-1-4",
  "category-3": "cat-1-4",
  "category-4": "cat-1-4",
  "category-5": "cat-5",
  "category-6": "cat-6",
  "category-7": "cat-7-8",
  "category-8": "cat-7-8",
  "category-9": "cat-9",
  "category-10": "cat-10-11",
  "category-11": "cat-10-11",
  "category-12": "cat-12",
  "category-13": "cat-13",
  "category-14": "cat-14",
  "category-15": "cat-15",
  "category-16": "cat-16-19",
  "category-17": "cat-16-19",
  "category-18": "cat-16-19",
  "category-19": "cat-16-19",
  "category-20": "cat-20",
  "category-21": "cat-21",
  "category-22": "cat-22",
  unknown: "unknown",
};

export const applianceCategoryLabels: Record<
  ResourceApplianceCategory,
  string
> = {
  "category-1": "Cat 1 (Heavy Tanker)",
  "category-2": "Cat 2 (Heavy Tanker)",
  "category-3": "Cat 3 (Heavy Tanker)",
  "category-4": "Cat 4 (Heavy Tanker)",
  "category-5": "Cat 5",
  "category-6": "Cat 6 (Heavy Tanker)",
  "category-7": "Cat 7 (Medium Tanker)",
  "category-8": "Cat 8 (Medium Tanker)",
  "category-9": "Cat 9 (Light Tanker)",
  "category-10": "Cat 10 (Medium Pumper)",
  "category-11": "Cat 11 (Medium Pumper)",
  "category-12": "Cat 12 (Personnel Transport)",
  "category-13": "Cat 13 (Heavy Bulk Water)",
  "category-14": "Cat 14 (Trailer)",
  "category-15": "Cat 15 (Fire Boat)",
  "category-16": "Cat 16 (Operational Support)",
  "category-17": "Cat 17 (Operational Support)",
  "category-18": "Cat 18 (Operational Support)",
  "category-19": "Cat 19 (Operational Support)",
  "category-20": "Cat 20 (Misc.)",
  "category-21": "Cat 21 (Light Rescue)",
  "category-22": "Cat 22 (Community First Responder)",
  unknown: "Unrecognised appliance",
};

export const applianceCategoryShortLabels: Record<
  ResourceApplianceCategory,
  string
> = {
  "category-1": "Cat 1",
  "category-2": "Cat 2",
  "category-3": "Cat 3",
  "category-4": "Cat 4",
  "category-5": "Cat 5",
  "category-6": "Cat 6",
  "category-7": "Cat 7",
  "category-8": "Cat 8",
  "category-9": "Cat 9",
  "category-10": "Cat 10",
  "category-11": "Cat 11",
  "category-12": "Cat 12",
  "category-13": "Cat 13",
  "category-14": "Cat 14",
  "category-15": "Cat 15",
  "category-16": "Cat 16",
  "category-17": "Cat 17",
  "category-18": "Cat 18",
  "category-19": "Cat 19",
  "category-20": "Cat 20",
  "category-21": "Cat 21",
  "category-22": "Cat 22",
  unknown: "Unrecognised appliance",
};

export const applianceCategoryGroupLabels: Record<
  ApplianceCategoryGroup,
  string
> = {
  "cat-1-4": "Cat 1-4 (Heavy Tanker)",
  "cat-5": "Cat 5",
  "cat-6": "Cat 6 (Heavy Tanker)",
  "cat-7-8": "Cat 7-8 (Medium Tanker)",
  "cat-9": "Cat 9 (Light Tanker)",
  "cat-10-11": "Cat 10-11 (Medium Pumper)",
  "cat-12": "Cat 12 (Personnel Transport)",
  "cat-13": "Cat 13 (Heavy Bulk Water)",
  "cat-14": "Cat 14 (Trailer)",
  "cat-15": "Cat 15 (Fire Boat)",
  "cat-16-19": "Cat 16-19 (Operational Support)",
  "cat-20": "Cat 20 (Misc.)",
  "cat-21": "Cat 21 (Light Rescue)",
  "cat-22": "Cat 22 (Community First Responder)",
  unknown: "Unrecognised appliance",
} as const;

export const applianceCategoryGroupShortLabels: Record<
  ApplianceCategoryGroup,
  string
> = {
  "cat-1-4": "Cat 1-4",
  "cat-5": "Cat 5",
  "cat-6": "Cat 6",
  "cat-7-8": "Cat 7-8",
  "cat-9": "Cat 9",
  "cat-10-11": "Cat 10-11",
  "cat-12": "Cat 12",
  "cat-13": "Cat 13",
  "cat-14": "Cat 14",
  "cat-15": "Cat 15",
  "cat-16-19": "Cat 16-19",
  "cat-20": "Cat 20",
  "cat-21": "Cat 21",
  "cat-22": "Cat 22",
  unknown: "Unknown",
} as const;

export const applianceCategoryGroupColors: Record<
  ApplianceCategoryGroup,
  string
> = {
  "cat-1-4": "#6C33D6",
  "cat-5": "#000",
  "cat-6": "#7839EE",
  "cat-7-8": "#864DF0",
  "cat-9": "#9361F1",
  "cat-10-11": "#A174F3",
  "cat-12": "#AE88F5",
  "cat-13": "#BC9CF7",
  "cat-14": "#C9B0F8",
  "cat-15": "#D7C4FA",
  "cat-16-19": "#E4D7FC",
  "cat-20": "#F2EBFD",
  "cat-21": "#000",
  "cat-22": "#000",
  unknown: "#000",
};

const nonFilterableApplianceCategoryGroups: ApplianceCategoryGroup[] = [
  "cat-5",
  "unknown",
];

export const applianceCategoryGroupOptions: FormControlOption<ApplianceCategoryGroup>[] =
  applianceCategoryGroups
    .filter(
      (categoryGroup) =>
        !nonFilterableApplianceCategoryGroups.includes(categoryGroup),
    )
    .map((categoryGroup) => ({
      label: applianceCategoryGroupLabels[categoryGroup],
      value: categoryGroup,
    }));

export const getApplianceCategoryGroupLabel = (
  category: ResourceApplianceCategory,
): string => {
  const categoryGroup = applianceCategoryGroupMap[category];

  return applianceCategoryGroupLabels[categoryGroup];
};
