/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

/**
 * Statuses an aircraft may be in.
 */
export type ResourceAircraftStatus =
  (typeof ResourceAircraftStatus)[keyof typeof ResourceAircraftStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceAircraftStatus = {
  available: "available",
  unavailable: "unavailable",
  limited: "limited",
  deployed: "deployed",
  "planned-dispatch": "planned-dispatch",
  standby: "standby",
  "standby-amended": "standby-amended",
  "standby-temp-asset": "standby-temp-asset",
  unserviceable: "unserviceable",
  "unserviceable-airdesk": "unserviceable-airdesk",
  "unserviceable-stood-down": "unserviceable-stood-down",
  "unserviceable-restricted": "unserviceable-restricted",
  "unserviceable-standby": "unserviceable-standby",
  "not-known": "not-known",
} as const;
