import styled from "styled-components";
import AircraftPositionLayer from "../../map/AircraftPosition/AircraftPositionLayer";
import MapProvider from "../../map/Map/MapProvider";
import MapView from "../../map/Map/MapView";

const StyledAircraftPositionMap = styled.div`
  aspect-ratio: 21 / 9;
  position: relative;
`;

interface AppliancePositionMapProps {
  resourceId: string;
}

const AircraftPositionMap = ({ resourceId }: AppliancePositionMapProps) => {
  return (
    <StyledAircraftPositionMap>
      <MapProvider lib="maplibre">
        <MapView isRounded>
          <AircraftPositionLayer resourceId={resourceId} />
        </MapView>
      </MapProvider>
    </StyledAircraftPositionMap>
  );
};

export default AircraftPositionMap;
