import type { ResourceAircraftCategory } from "../../.rest-hooks/types/resources.yml/resourceAircraftCategory";
import { ResourceAircraftStatus } from "../../.rest-hooks/types/resources.yml/resourceAircraftStatus";

const getAircraftResourceCategoryStatus = (
  category: ResourceAircraftCategory,
  status: ResourceAircraftStatus,
) => {
  if (!status) {
    return `aeroplane-${ResourceAircraftStatus["not-known"]}`;
  }
  // Categories prefixed with "rw" relate to helicopters
  if (category.includes("rw")) {
    return `helicopter-${status}`;
  }

  // All other categories relate to aeroplanes
  return `aeroplane-${status}`;
};

export default getAircraftResourceCategoryStatus;
