import { FieldGrid, media } from "@app/design-system";
import styled from "styled-components";
import type { ResourceHeavyPlant } from "../../../../.rest-hooks/types/resources.yml/resourceHeavyPlant";
import { heavyPlantCategoryLabels } from "../../../config/heavyPlantCategory";
import { EMDASH } from "../../../lib/strings";
import IncidentName from "../../ui/IncidentName/IncidentName";
import ApplianceAssignmentDateRange from "../ApplianceDetailModal/ApplianceAssignmentDateRange";
import HeavyPlantStatusIndicator from "../ResourceStatusIndicator/HeavyPlantStatusIndicator";

const StyledApplianceActivitySummary = styled.div`
  display: grid;
  gap: 1.5rem;
  align-items: start;

  @media ${media.lg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledColumn = styled.div`
  display: grid;
  row-gap: 1rem;
`;

export interface HeavyPlantActivitySummaryProps {
  heavyPlant: ResourceHeavyPlant;
}

const HeavyPlantActivitySummary = ({
  heavyPlant,
}: HeavyPlantActivitySummaryProps) => {
  return (
    <StyledApplianceActivitySummary>
      <StyledColumn>
        <FieldGrid>
          <FieldGrid.Item label="Status:">
            <HeavyPlantStatusIndicator status={heavyPlant.attributes.status} />
          </FieldGrid.Item>
        </FieldGrid>
        <FieldGrid>
          {heavyPlant.attributes.assignment && (
            <>
              <FieldGrid.Item label="Assigned to:">
                <IncidentName
                  incidentId={heavyPlant.attributes.assignment.incidentId}
                />
              </FieldGrid.Item>
              <FieldGrid.Item label="Current deployment:">
                <ApplianceAssignmentDateRange
                  assignment={heavyPlant.attributes.assignment}
                />
              </FieldGrid.Item>
            </>
          )}
        </FieldGrid>
      </StyledColumn>
      <StyledColumn>
        <FieldGrid>
          <FieldGrid.Item label="Category:">
            {heavyPlantCategoryLabels[heavyPlant.attributes.category] || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="External ID:">
            {heavyPlant.attributes.externalId || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Make:">
            {heavyPlant.attributes.make || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Model:">
            {heavyPlant.attributes.model || EMDASH}
          </FieldGrid.Item>
        </FieldGrid>
        <FieldGrid>
          <FieldGrid.Item label="Owner agency:">
            {heavyPlant.attributes.ownerAgency || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Major area:">
            {heavyPlant.attributes.homeLocation.majorArea || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Minor area:">
            {heavyPlant.attributes.homeLocation.minorArea || EMDASH}
          </FieldGrid.Item>
          <FieldGrid.Item label="Postcode:">
            {heavyPlant.attributes.homeLocation.postcode || EMDASH}
          </FieldGrid.Item>
        </FieldGrid>
      </StyledColumn>
    </StyledApplianceActivitySummary>
  );
};

export default HeavyPlantActivitySummary;
