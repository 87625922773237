import type { ResourceAircraftAssignment } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftAssignment";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";

interface AircraftAssignmentDateRangeProps {
  assignment: ResourceAircraftAssignment;
}

const AircraftAssignmentDateRange = ({
  assignment,
}: AircraftAssignmentDateRangeProps) => {
  if (!assignment.assignedTo) {
    return (
      <>Start: {getFormattedDateAndTime(assignment.assignedFrom * 1000)}</>
    );
  }

  return (
    <>
      Start: {getFormattedDateAndTime(assignment.assignedFrom * 1000)} <br />
      End: {getFormattedDateAndTime(assignment.assignedTo * 1000)}
    </>
  );
};

export default AircraftAssignmentDateRange;
