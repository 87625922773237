import { StatusIndicator, type StatusIndicatorSize } from "@app/design-system";
import React from "react";
import type { ResourceHeavyPlantStatus } from "../../../../.rest-hooks/types/resources.yml/resourceHeavyPlantStatus";
import {
  getHeavyPlantStatusIcon,
  getHeavyPlantStatusLabel,
} from "../../../config/heavyPlantStatus";

interface HeavyPlantStatusIndicatorProps {
  size?: StatusIndicatorSize;
  status: ResourceHeavyPlantStatus;
}

const HeavyPlantStatusIndicator = ({
  size,
  status,
}: HeavyPlantStatusIndicatorProps) => {
  const icon = getHeavyPlantStatusIcon(status);
  const label = getHeavyPlantStatusLabel(status);

  return (
    <StatusIndicator icon={icon} size={size}>
      {label}
    </StatusIndicator>
  );
};

export default HeavyPlantStatusIndicator;
