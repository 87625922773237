import { FieldGrid, media } from "@app/design-system";
import styled from "styled-components";
import type { ResourceAircraft } from "../../../../.rest-hooks/types/resources.yml/resourceAircraft";
import { formatAircraftCategory } from "../../../config/aircraftCategories";
import { formatAircraftServiceRoles } from "../../../config/aircraftServiceRoles";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";

const StyledApplianceSummary = styled.div`
  display: grid;
  gap: 1.5rem;
  align-items: start;

  @media ${media.lg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledColumn = styled.div`
  display: grid;
  gap: 1rem;
`;

interface AircraftSummaryProps {
  aircraft: ResourceAircraft;
}

const AircraftSummary = ({ aircraft }: AircraftSummaryProps) => {
  const baseLocationLabel = aircraft.attributes.homeLocation?.isTemporary
    ? "Temporary"
    : "Nominated";

  return (
    <StyledApplianceSummary>
      <StyledColumn>
        <FieldGrid>
          <FieldGrid.Item label="Category:">
            {formatAircraftCategory(aircraft.attributes.category)}
          </FieldGrid.Item>
          <FieldGrid.Item label="RFS call sign:">
            {aircraft.attributes.callSign || <FallbackElement />}
          </FieldGrid.Item>
          <FieldGrid.Item label="Rego call sign:">
            {aircraft.attributes.registration || <FallbackElement />}
          </FieldGrid.Item>
          <FieldGrid.Item label="Make:">
            {aircraft.attributes.make}
          </FieldGrid.Item>
          <FieldGrid.Item label="Model:">
            {aircraft.attributes.model}
          </FieldGrid.Item>
          <FieldGrid.Item label="Owner agency:">
            {aircraft.attributes.ownerAgency}
          </FieldGrid.Item>
          <FieldGrid.Item label="Seating capacity:">
            {aircraft.attributes.seatingCapacity ?? <FallbackElement />}
          </FieldGrid.Item>
        </FieldGrid>

        <FieldGrid>
          <FieldGrid.Item label="Approved service roles:">
            {formatAircraftServiceRoles(
              aircraft.attributes.approvedServiceRoles,
            )}
          </FieldGrid.Item>
        </FieldGrid>

        <FieldGrid>
          <FieldGrid.Item label={`${baseLocationLabel} operating base:`}>
            {aircraft.attributes.homeLocation?.name || <FallbackElement />}
          </FieldGrid.Item>
        </FieldGrid>
      </StyledColumn>
    </StyledApplianceSummary>
  );
};

export default AircraftSummary;
