import styled from "styled-components";
import AppliancePositionLayer from "../../map/AppliancePositions/AppliancePositionLayer";
import MapProvider from "../../map/Map/MapProvider";
import MapView from "../../map/Map/MapView";

const StyledAppliancePositionMap = styled.div`
  aspect-ratio: 3 / 2;
  position: relative;
`;

interface AppliancePositionMapProps {
  resourceId: string;
}

const AppliancePositionMap = ({ resourceId }: AppliancePositionMapProps) => {
  return (
    <StyledAppliancePositionMap>
      <MapProvider lib="maplibre">
        <MapView isRounded>
          <AppliancePositionLayer resourceId={resourceId} />
        </MapView>
      </MapProvider>
    </StyledAppliancePositionMap>
  );
};

export default AppliancePositionMap;
