import {
  Tooltip,
  buttonReset,
  focusStyles,
  showToast,
  weakStyles,
  type TooltipInstance,
} from "@app/design-system";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StyledCopyToClipboardText = styled.button`
  ${buttonReset}
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-self: start;
  ${(p) => p.theme.typography.variants.bodyDefault}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  text-wrap: balance;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    inset: 0 -0.25rem;
    z-index: -1;
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
    transition: background-color
      ${(p) => `${p.theme.anim.duration.md}ms ${p.theme.anim.curve}`};
  }

  &:hover::after {
    ${weakStyles.background.hover}
  }

  &:active::after {
    ${weakStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const PROMPT_TO_COPY = "Copy to clipboard";
const SUCCESSFULLY_COPIED = "Copied!";

interface CopyableTextProps {
  children?: React.ReactNode;
  textToCopy: string;
}

const CopyableText = ({ children, textToCopy }: CopyableTextProps) => {
  const [label, setLabel] = useState(PROMPT_TO_COPY);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const tooltipRef = useRef<TooltipInstance | null>(null);

  const onClick = () => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setLabel(SUCCESSFULLY_COPIED);

        // Clear any existing timeout
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }

        // Set a new timeout
        timeoutId.current = setTimeout(() => {
          setLabel(PROMPT_TO_COPY);
          timeoutId.current = null;
        }, 2000);

        tooltipRef.current?.show();
      },
      () => {
        showToast({
          title: "Unable to copy",
          variant: "error",
          id: `${textToCopy}-clipboard-error`,
        });
      },
    );
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <Tooltip
      delay
      hideOnClick={false}
      leaveDelay={label !== PROMPT_TO_COPY}
      message={label}
      tooltipRef={(instance) => (tooltipRef.current = instance)}
    >
      <StyledCopyToClipboardText onClick={onClick}>
        {children}
      </StyledCopyToClipboardText>
    </Tooltip>
  );
};

export default CopyableText;
