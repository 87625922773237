import {
  CopyToClipboard,
  IconButton,
  type IconButtonSize,
  type IconButtonVariant,
  showToast,
} from "@app/design-system";
import { getResourceUrl } from "./utils";

export interface CopyLinkToResourceButtonProps {
  resourceId: string;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
}

const CopyLinkToResourceButton = ({
  resourceId,
  size,
  variant,
}: CopyLinkToResourceButtonProps) => {
  const onClick = () => {
    const url = getResourceUrl({ resourceId });
    navigator.clipboard.writeText(url).then(
      () => {
        showToast({
          title: "Copied",
          message: "Resource link copied to clipboard",
          variant: "success",
          id: `${resourceId}-clipboard-success`,
        });
      },
      () => {
        showToast({
          title: "Unable to copy",
          message: "Error copying resource link to clipboard",
          variant: "error",
          id: `${resourceId}-clipboard-error`,
        });
      },
    );
  };

  return (
    <IconButton
      icon={CopyToClipboard}
      label="Copy link to resource"
      onClick={onClick}
      size={size}
      variant={variant}
    />
  );
};

export default CopyLinkToResourceButton;
