import type { FeatureCollection, Geometry } from "geojson";
import uniqBy from "lodash/uniqBy";
import type mapboxgl from "mapbox-gl";
import { isMaplibre } from "./isMaplibre";

const ICONS_FOLDER = "icons";
const PATTERNS_FOLDER = "patterns";

export interface MapImage {
  imageId: string;
  src: string;
}

interface LoadImageParams {
  imageId: string;
  map: mapboxgl.Map | maplibregl.Map;
  pixelRatio?: number;
  src: string;
  stretchX?: [number, number][];
  stretchY?: [number, number][];
  content?: [number, number, number, number];
  signal?: AbortSignal;
}

export async function loadImage({
  imageId,
  map,
  pixelRatio,
  src,
  stretchX,
  stretchY,
  content,
  signal,
}: LoadImageParams) {
  // NOTE: We're forcing an additional microtask here as synchronous aborts cause a race condition with effect cleanup
  await Promise.resolve();

  return new Promise<void>((resolve, reject) => {
    if (signal?.aborted) {
      reject(new DOMException("loadImage was aborted", "AbortError"));
      return;
    }

    if (map.hasImage(imageId)) {
      resolve();
      return;
    }

    if (!isMaplibre(map)) {
      map.loadImage(src, (error, image) => {
        if (signal?.aborted) {
          reject(new DOMException("loadImage was aborted", "AbortError"));
          return;
        }

        if (error || !image) {
          return reject(error);
        }

        if (!map.hasImage(imageId)) {
          map.addImage(imageId, image, {
            pixelRatio,
            stretchX,
            stretchY,
            content,
          });
        }

        return resolve();
      });
    } else {
      map
        .loadImage(src)
        .then((response) => {
          if (signal?.aborted) {
            return reject(
              new DOMException("loadImage was aborted", "AbortError"),
            );
          }

          if (!response.data) {
            return reject();
          }

          if (!map.hasImage(imageId)) {
            map.addImage(imageId, response.data, {
              pixelRatio,
              stretchX,
              stretchY,
              content,
            });
          }

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

type LoadScopedImageParams = Omit<LoadImageParams, "src">;

export function loadIcon(params: LoadScopedImageParams) {
  return loadImage({
    ...params,
    src: `/${ICONS_FOLDER}/${params.imageId}.png`,
  });
}

export function loadPattern(params: LoadScopedImageParams) {
  return loadImage({
    ...params,
    src: `/${PATTERNS_FOLDER}/${params.imageId}.png`,
  });
}

interface LoadImagesParams<P> {
  geojson: FeatureCollection<Geometry | null, P>;
  getImageId: (properties: P) => string;
  getSrc: (imageId: string) => string;
  map: mapboxgl.Map | maplibregl.Map;
  pixelRatio?: number;
  signal?: AbortSignal;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function loadImages<P extends {}>({
  geojson,
  getImageId,
  getSrc,
  map,
  pixelRatio,
  signal,
}: LoadImagesParams<P>) {
  const uniqueFeatures = uniqBy(geojson.features, (feature) =>
    getImageId(feature.properties),
  );

  return Promise.all(
    uniqueFeatures.map((feature) => {
      const imageId = getImageId(feature.properties);
      const src = getSrc(imageId);
      return loadImage({
        imageId,
        map,
        pixelRatio,
        signal,
        src,
      });
    }),
  );
}

type LoadScopedImagesParams<P> = Omit<LoadImagesParams<P>, "getSrc">;

// eslint-disable-next-line @typescript-eslint/ban-types
export function loadIcons<P extends {}>(params: LoadScopedImagesParams<P>) {
  return loadImages({
    getSrc: (imageId) => `/${ICONS_FOLDER}/${imageId}.png`,
    ...params,
  });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function loadPatterns<P extends {}>(params: LoadScopedImagesParams<P>) {
  return loadImages({
    getSrc: (imageId) => `/${PATTERNS_FOLDER}/${imageId}.png`,
    ...params,
  });
}
