import { media, Skeleton, Text } from "@app/design-system";
import styled from "styled-components";

const BannerSkeleton = styled(Skeleton)`
  width: 100%;

  @media ${media.lg} {
    max-width: 640px;
  }
`;

const SkeletonIncidentNavBanner = () => {
  return (
    <Text size="bodyDefault">
      <BannerSkeleton />
    </Text>
  );
};

export default SkeletonIncidentNavBanner;
