import { Skeleton, StatusMessage, focusStyles } from "@app/design-system";
import styled from "styled-components";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import QueryParams from "../../../config/queryParams";
import useQueryAsyncValue from "../../../hooks/useQueryAsyncValue";
import AsyncView from "../../util/AsyncView/AsyncView";
import TaskLink from "../../util/TaskLink/TaskLink";
import AlertLevelIcon from "../AlertLevelIcon/AlertLevelIcon";

const StyledIncidentName = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.375rem;
`;

const StyledIncidentLink = styled.a`
  color: inherit;

  &:hover {
    color: ${(p) => p.theme.colors.neutrals.text};
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

interface IncidentNameProps {
  incidentId: string;
}

const IncidentName = ({ incidentId }: IncidentNameProps) => {
  const incidentQuery = useGetIncidentsId(incidentId, {
    query: {
      select: (data) => data.data.data,
    },
  });

  const incidentResult = useQueryAsyncValue({ query: incidentQuery });

  return (
    <AsyncView
      {...incidentResult}
      errorState={
        <StatusMessage variant="error">Unable to load incident</StatusMessage>
      }
      loadingState={<Skeleton />}
    >
      {(incident) => (
        <StyledIncidentName>
          <AlertLevelIcon
            alertLevel={getAlertLevelFromIncident(incident)}
            size="sm"
          />
          <div>
            <TaskLink
              as={`/incidents/${incident.id}`}
              href={{
                pathname: "/incidents/[id]",
                query: { [QueryParams.INCIDENT_ID]: incident.id },
              }}
              legacyBehavior
            >
              <StyledIncidentLink>
                {incident.attributes.name || <>&mdash;</>}
              </StyledIncidentLink>
            </TaskLink>
          </div>
        </StyledIncidentName>
      )}
    </AsyncView>
  );
};

export default IncidentName;
