/**
 * NOTE: This is a very cheap trick as we needed a quick way to handle a
 * change away from callbacks for this function in maplibre 4.X.X
 * Checking a function's length returns the number of required params it's
 * expecting, which in this case we use to check between callback vs
 * promise setup: https://stackoverflow.com/questions/4138012/checks-how-many-arguments-a-function-takes-in-javascript
 */
export const isMaplibre = (
  map: maplibregl.Map | mapboxgl.Map,
): map is maplibregl.Map => {
  return map.loadImage.length !== 2;
};
