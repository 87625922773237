import type { ResourceAircraftAssignment } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftAssignment";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import IncidentName from "../../ui/IncidentName/IncidentName";

interface AircraftAssignmentIncidentProps {
  assignment: ResourceAircraftAssignment;
}

const AircraftAssignmentIncident = ({
  assignment,
}: AircraftAssignmentIncidentProps) => {
  const { incidentId, incidentName } = assignment;

  if (!incidentId && !incidentName) {
    return <FallbackElement />;
  }

  if (!incidentId) {
    return <>{incidentName}</>;
  }

  return <IncidentName incidentId={incidentId} />;
};

export default AircraftAssignmentIncident;
