// Takes float and gets the right side, returning a decimal value

const getDecimal = (value: number) => {
  if (Number.isInteger(value)) {
    return value;
  }

  const decimal = value.toString().split(".")[1];
  return Number(decimal);
};

export default getDecimal;
