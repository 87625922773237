import {
  AVLStatusAvailable,
  AVLStatusNotKnown,
  AVLStatusResponding,
  AVLStatusUndispatchable,
  type FormControlOption,
} from "@app/design-system";
import { ResourceHeavyPlantStatus } from "../../.rest-hooks/types/resources.yml/resourceHeavyPlantStatus";

const resourceHeavyPlantStatuses = Object.values(
  ResourceHeavyPlantStatus,
).toSorted();

export const isResourceHeavyPlantStatus = (
  value: string,
): value is ResourceHeavyPlantStatus => {
  return resourceHeavyPlantStatuses.includes(value as ResourceHeavyPlantStatus);
};

export const heavyPlantStatusLabels: Record<ResourceHeavyPlantStatus, string> =
  {
    available: "Available",
    dispatched: "Dispatched",
    undispatchable: "Undispatchable",
    unknown: "Unknown",
  } as const;

export const heavyPlantStatusOptions: FormControlOption<ResourceHeavyPlantStatus>[] =
  resourceHeavyPlantStatuses.map((status) => ({
    label: heavyPlantStatusLabels[status],
    value: status,
  }));

export const heavyPlantStatusIconMap: Record<
  ResourceHeavyPlantStatus,
  ReactSVGComponent
> = {
  available: AVLStatusAvailable,
  dispatched: AVLStatusResponding,
  unknown: AVLStatusNotKnown,
  undispatchable: AVLStatusUndispatchable,
} as const;

export function getHeavyPlantStatusIcon(
  heavyPlantStatus?: ResourceHeavyPlantStatus | undefined,
): ReactSVGComponent {
  if (!heavyPlantStatus || !isResourceHeavyPlantStatus(heavyPlantStatus))
    return heavyPlantStatusIconMap.unknown;

  return heavyPlantStatusIconMap[heavyPlantStatus];
}

export function getHeavyPlantStatusLabel(
  heavyPlantStatus?: ResourceHeavyPlantStatus | undefined,
) {
  if (!heavyPlantStatus || !isResourceHeavyPlantStatus(heavyPlantStatus))
    return "Not known";

  return heavyPlantStatusLabels[heavyPlantStatus];
}
