/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type ResourceAircraftServiceRole =
  (typeof ResourceAircraftServiceRole)[keyof typeof ResourceAircraftServiceRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceAircraftServiceRole = {
  "aerial-application-spraying-spreading":
    "aerial-application-spraying-spreading",
  "aerial-ignition-drip-torch": "aerial-ignition-drip-torch",
  "aerial-ignition-incendiaries-machine":
    "aerial-ignition-incendiaries-machine",
  "aerial-ignition-incendiaries-manual": "aerial-ignition-incendiaries-manual",
  "air-attack-supervision": "air-attack-supervision",
  "airwork-other": "airwork-other",
  "animal-control-aerial-baiting": "animal-control-aerial-baiting",
  "animal-control-aerial-shooting": "animal-control-aerial-shooting",
  firebombing: "firebombing",
  "incident-detection": "incident-detection",
  "incident-observation": "incident-observation",
  "incident-observation-tech-aided": "incident-observation-tech-aided",
  "incident-mapping": "incident-mapping",
  "low-level-survey": "low-level-survey",
  rappelling: "rappelling",
  "refueler-bridging": "refueler-bridging",
  "refueler-mobile": "refueler-mobile",
  "rescue-winching": "rescue-winching",
  "retardant-mixer-mobile": "retardant-mixer-mobile",
  "sling-load-long-line": "sling-load-long-line",
  "sling-load-short-line": "sling-load-short-line",
  "suppressant-mixer-mobile": "suppressant-mixer-mobile",
  "transport-fire-emergency-crew": "transport-fire-emergency-crew",
  "transport-general-cargo": "transport-general-cargo",
  "transport-passenger-low-capacity": "transport-passenger-low-capacity",
  "transport-passenger-high-capacity": "transport-passenger-high-capacity",
  "transport-rescue-crew-equipment": "transport-rescue-crew-equipment",
  winching: "winching",
} as const;
