import { ResourceAircraftServiceRole } from "../../.rest-hooks/types/resources.yml/resourceAircraftServiceRole";

const aircraftServiceRoles = Object.values(ResourceAircraftServiceRole);

export const aircraftServiceRoleLabels: Record<
  ResourceAircraftServiceRole,
  string
> = {
  "aerial-application-spraying-spreading":
    "Aerial Application - Spraying and Spreading",
  "aerial-ignition-drip-torch": "Aerial Ignition - Drip Torch",
  "aerial-ignition-incendiaries-machine":
    "Aerial Ignition - Incendiaries - Machine",
  "aerial-ignition-incendiaries-manual":
    "Aerial Ignition - Incendiaries - Manual",
  "air-attack-supervision": "Air Attack Supervision",
  "airwork-other": "Airwork - Other",
  "animal-control-aerial-baiting": "Animal Control - Aerial Baiting",
  "animal-control-aerial-shooting": "Animal Control - Aerial Shooting",
  "incident-detection": "Incident Detection",
  "incident-mapping": "Incident Mapping",
  "incident-observation": "Incident Observation",
  "incident-observation-tech-aided": "Incident Observation - Tech Aided",
  "low-level-survey": "Low Level Survey",
  "refueler-bridging": "Refueler - Bridging",
  "refueler-mobile": "Refueler - Mobile",
  "rescue-winching": "Rescue - Winching",
  "retardant-mixer-mobile": "Retardant Mixer - Mobile",
  "sling-load-long-line": "Sling Load - Long Line",
  "sling-load-short-line": "Sling Load - Short Line",
  "suppressant-mixer-mobile": "Suppressant Mixer - Mobile",
  "transport-fire-emergency-crew": "Transport - Fire & Emergency Crew",
  "transport-general-cargo": "Transport - General Cargo",
  "transport-passenger-high-capacity": "Transport - Passenger - High Capacity",
  "transport-passenger-low-capacity": "Transport - Passenger - Low Capacity",
  "transport-rescue-crew-equipment": "Transport - Rescue Crew & Equipment",
  firebombing: "Firebombing",
  rappelling: "Rappelling",
  winching: "Winching",
};

export const isAircraftServiceRole = (
  serviceRole: string,
): serviceRole is ResourceAircraftServiceRole => {
  return aircraftServiceRoles.includes(
    serviceRole as ResourceAircraftServiceRole,
  );
};

export const formatAircraftServiceRole = (
  serviceRole: ResourceAircraftServiceRole | undefined,
): string => {
  if (!serviceRole) return "—";

  if (isAircraftServiceRole(serviceRole)) {
    return aircraftServiceRoleLabels[serviceRole];
  }

  return serviceRole;
};

export const formatAircraftServiceRoles = (
  serviceRoles: ResourceAircraftServiceRole[] | undefined,
): string => {
  if (!serviceRoles) return "—";

  return serviceRoles.map((role) => formatAircraftServiceRole(role)).join(", ");
};
