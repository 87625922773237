import type { ResourceHeavyPlant } from "../../../../.rest-hooks/types/resources.yml/resourceHeavyPlant";
import HeavyPlantActivitySummary from "./HeavyPlantActivitySummary";

export interface ApplianceDetailProps {
  heavyPlant: ResourceHeavyPlant;
}

const HeavyPlantDetail = ({ heavyPlant: appliance }: ApplianceDetailProps) => {
  return <HeavyPlantActivitySummary heavyPlant={appliance} />;
};

export default HeavyPlantDetail;
