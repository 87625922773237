interface AddLeadingZerosProps {
  addLength: number;
  value: number;
}

const addLeadingZeros = ({ value, addLength }: AddLeadingZerosProps) => {
  const stringValue = value.toString();
  if (stringValue.length < 2) {
    return stringValue.padStart(addLength, "0");
  }
  return value;
};

export default addLeadingZeros;
