/**
 * @see https://firetrail.atlassian.net/browse/AP2-1288
 */
export const MAX_AIRCRAFT_PER_PAGE = 1000;

export const AIRCRAFT_POSITIONS_SOURCE_ID = "aircraft-positions";
export const AIRCRAFT_POSITIONS_LAYER_ID = "aircraft-positions";

export const AIRCRAFT = {
  "aeroplane-available": "/icons/aircraft-limited.png",
  "aeroplane-unavailable": "/icons/aircraft-not-available.png",
  "aeroplane-limited": "/icons/aircraft-limited.png",
  "aeroplane-deployed": "/icons/aircraft-deployed.png",
  "aeroplane-planned-dispatch": "/icons/aircraft-planned-dispatch.png",
  "aeroplane-not-known": "/icons/aircraft-not-known.png",
  "aeroplane-standby-amended": "/icons/aircraft-planned-dispatch.png",
  "aeroplane-standby-temp-asset": "/icons/aircraft-planned-dispatch.png",
  "aeroplane-unserviceable-airdesk": "/icons/aircraft-not-available.png",
  "aeroplane-unserviceable-restricted": "/icons/aircraft-not-available.png",
  "aeroplane-unserviceable-standby": "/icons/aircraft-not-available.png",
  "aeroplane-unserviceable-stood-down": "/icons/aircraft-not-available.png",
  "aeroplane-standby": "/icons/aircraft-planned-dispatch.png",
  "aeroplane-unserviceable": "/icons/aircraft-not-available.png",
  "helicopter-available": "/icons/helicopter-limited.png",
  "helicopter-unavailable": "/icons/helicopter-not-available.png",
  "helicopter-limited": "/icons/helicopter-limited.png",
  "helicopter-deployed": "/icons/helicopter-deployed.png",
  "helicopter-planned-dispatch": "/icons/helicopter-planned-dispatch.png",
  "helicopter-not-known": "/icons/helicopter-not-known.png",
  "helicopter-standby-amended": "/icons/helicopter-planned-dispatch.png",
  "helicopter-standby-temp-asset": "/icons/helicopter-planned-dispatch.png",
  "helicopter-unserviceable-airdesk": "/icons/helicopter-not-available.png",
  "helicopter-unserviceable-restricted": "/icons/helicopter-not-available.png",
  "helicopter-unserviceable-standby": "/icons/helicopter-not-available.png",
  "helicopter-unserviceable-stood-down": "/icons/helicopter-not-available.png",
  "helicopter-standby": "/icons/helicopter-planned-dispatch.png",
  "helicopter-unserviceable": "/icons/helicopter-not-available.png",
};
