import getDMSConversion from "../components/map/LngLat/getDMSConversion";

const formatCoordinates = (coordinates: number[] | undefined) => {
  if (!coordinates) {
    return "—";
  }
  const [lng, lat] = coordinates;

  const longitude = getDMSConversion({
    value: lng.toFixed(6).toString(),
    coordinateType: "longitude",
  });

  const latitude = getDMSConversion({
    value: lat.toFixed(6).toString(),
    coordinateType: "latitude",
  });

  return `Lng: ${longitude} | Lat: ${latitude}`;
};

export default formatCoordinates;
