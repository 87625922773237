import { FieldGrid, media } from "@app/design-system";
import styled from "styled-components";
import type { ResourceAircraft } from "../../../../.rest-hooks/types/resources.yml/resourceAircraft";
import { formatAircraftServiceRole } from "../../../config/aircraftServiceRoles";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import AircraftStatusIndicator from "../ResourceStatusIndicator/AircraftStatusIndicator";
import AircraftAssignmentDateRange from "./AircraftAssignmentDateRange";
import AircraftAssignmentIncident from "./AircraftAssignmentIncident";
import AircraftCurrentLocation from "./AircraftCurrentLocation";

const StyledApplianceActivitySummary = styled.div`
  display: grid;
  gap: 1.5rem;
  align-items: start;

  @media ${media.lg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledColumn = styled.div`
  display: grid;
  row-gap: 1rem;
`;

interface AircraftActivitySummaryProps {
  aircraft: ResourceAircraft;
}

const AircraftActivitySummary = ({
  aircraft,
}: AircraftActivitySummaryProps) => {
  return (
    <StyledApplianceActivitySummary>
      <StyledColumn>
        <FieldGrid>
          <FieldGrid.Item label="Status:">
            <AircraftStatusIndicator status={aircraft.attributes.status} />
          </FieldGrid.Item>
        </FieldGrid>

        {aircraft.attributes.assignment && (
          <FieldGrid>
            <FieldGrid.Item label="Assigned to:">
              <AircraftAssignmentIncident
                assignment={aircraft.attributes.assignment}
              />
            </FieldGrid.Item>
            <FieldGrid.Item label="Current deployment:">
              <AircraftAssignmentDateRange
                assignment={aircraft.attributes.assignment}
              />
            </FieldGrid.Item>
            <FieldGrid.Item label="Requesting agency:">
              {aircraft.attributes.assignment.requestingAgency || (
                <FallbackElement />
              )}
            </FieldGrid.Item>
            <FieldGrid.Item label="Primary service role:">
              {formatAircraftServiceRole(
                aircraft.attributes.assignment.serviceRole,
              )}
            </FieldGrid.Item>
          </FieldGrid>
        )}
        {aircraft.attributes.servicePeriod && (
          <FieldGrid>
            <FieldGrid.Item label="Standby role:">
              {formatAircraftServiceRole(
                aircraft.attributes.servicePeriod.serviceRole,
              )}
            </FieldGrid.Item>
            <FieldGrid.Item label="Standby period:">
              Start:{" "}
              {getFormattedDateAndTime(
                aircraft.attributes.servicePeriod.startedAt * 1000,
              )}
              <br />
              End:{" "}
              {getFormattedDateAndTime(
                aircraft.attributes.servicePeriod.endsAt * 1000,
              )}
            </FieldGrid.Item>
          </FieldGrid>
        )}
      </StyledColumn>
      <StyledColumn>
        <AircraftCurrentLocation aircraft={aircraft} />
      </StyledColumn>
    </StyledApplianceActivitySummary>
  );
};

export default AircraftActivitySummary;
