import { StatusIndicator, type StatusIndicatorSize } from "@app/design-system";
import type { ResourceAircraftStatus } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftStatus";
import {
  getAircraftStatusGroupLabel,
  getAircraftStatusIcon,
} from "../../../config/aircraftStatus";

interface AircraftStatusIndicatorProps {
  status: ResourceAircraftStatus;
  size?: StatusIndicatorSize;
}

const AircraftStatusIndicator = ({
  status,
  size,
}: AircraftStatusIndicatorProps) => {
  const icon = getAircraftStatusIcon(status);
  const label = getAircraftStatusGroupLabel(status);

  return (
    <StatusIndicator icon={icon} size={size}>
      {label}
    </StatusIndicator>
  );
};

export default AircraftStatusIndicator;
