import { StatusIndicator, type StatusIndicatorSize } from "@app/design-system";
import type { ResourceApplianceStatus } from "../../../../.rest-hooks/types/resources.yml/resourceApplianceStatus";
import {
  getApplianceStatusIcon,
  getApplianceStatusLabel,
} from "../../../config/applianceStatus";

interface ApplianceStatusIndicatorProps {
  status: ResourceApplianceStatus;
  size?: StatusIndicatorSize;
}

const ApplianceStatusIndicator = ({
  status,
  size,
}: ApplianceStatusIndicatorProps) => {
  const icon = getApplianceStatusIcon(status);
  const label = getApplianceStatusLabel(status);

  return (
    <StatusIndicator icon={icon} size={size}>
      {label}
    </StatusIndicator>
  );
};

export default ApplianceStatusIndicator;
