import { SECOND } from "@kablamo/kerosene";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { QueryOptions } from "../hooks/useMapServerQueryData/useMapServerQueryData";

export const TEN_SECONDS = SECOND * 10;

export const AVL_POSITIONS_SOURCE_ID = "avl-positions";
export const AVL_POSITIONS_OPTION_APPLIANCES_SOURCE_ID =
  `${AVL_POSITIONS_SOURCE_ID}-appliances` as const;
export const AVL_POSITIONS_OPTION_PORTABLE_SOURCE_ID =
  `${AVL_POSITIONS_SOURCE_ID}-portable` as const;

export const AVL_POSITIONS_LAYER_ID = "avl-positions";
export const AVL_POSITIONS_OPTION_APPLIANCES_LAYER_ID =
  `${AVL_POSITIONS_LAYER_ID}-appliances` as const;
export const AVL_POSITIONS_OPTION_PORTABLE_LAYER_ID =
  `${AVL_POSITIONS_LAYER_ID}-portable` as const;

export const AVL_EMERGENCY_SOURCE_ID = "avl-emergency";
export const AVL_EMERGENCY_APPLIANCES_SOURCE_ID =
  `${AVL_EMERGENCY_SOURCE_ID}-appliances` as const;
export const AVL_EMERGENCY_PORTABLE_SOURCE_ID =
  `${AVL_EMERGENCY_SOURCE_ID}-portable` as const;

export const AVL_EMERGENCY_LAYER_ID = "avl-emergency";
export const AVL_EMERGENCY_APPLIANCES_LAYER_ID =
  `${AVL_EMERGENCY_LAYER_ID}-appliances` as const;
export const AVL_EMERGENCY_PORTABLE_LAYER_ID =
  `${AVL_EMERGENCY_LAYER_ID}-portable` as const;

export const AVL_DATA_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/MapServer/0/query`;
export const AVL_DATA_SYMBOLOGY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/FeatureServer/0?f=pjson`;

export const AVL_DATA_OPTION_APPLIANCES_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/MapServer/1/query`;
export const AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/FeatureServer/1?f=pjson`;

export const AVL_DATA_OPTION_PORTABLE_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/MapServer/2/query`;
export const AVL_DATA_OPTION_PORTABLE_SYMBOLOGY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/FeatureServer/2?f=pjson`;
export const AVL_PORTABLE_SYMBOLOGY_OVERRIDES = {
  "Alerted,N/A": "/icons/avl-portable-alerted.png",
  "At Station,N/A": "/icons/avl-portable-at-station.png",
  "Available,N/A": "/icons/avl-portable-available.png",
  "Dispatchable,N/A": "/icons/avl-portable-dispatchable.png",
  "Emergency,N/A": "/icons/avl-portable-emergency.png",
  "Not Available,N/A": "/icons/avl-portable-not-available.png",
  // Yes, the 'k' is meant to be lowercase
  "Not known,N/A": "/icons/avl-portable-not-known.png",
  "On Scene,N/A": "/icons/avl-portable-on-scene.png",
  "Proceeding,N/A": "/icons/avl-portable-proceeding.png",
  "Responding,N/A": "/icons/avl-portable-responding.png",
};

export const QUERY_OPTIONS_COMMON: QueryOptions = {
  cacheSize: 0,
  queryParams: {
    outFields:
      "BfNumber,Brigade,CallSign,Category,Description,DeviceType,Emergency,GpsStatus,LicenseNo,RadioId,ReportAge,Status,DirectionHor,StatusCategory,TalkGroupId,ObjectId",
  },
};

export const QUERY_OPTIONS_LEGACY: QueryOptions = {
  ...QUERY_OPTIONS_COMMON,
  queryParams: {
    ...QUERY_OPTIONS_COMMON.queryParams,
    where: "ReportAge LIKE '0h%'",
  },
};

export const AVL_EMERGENCY_QUERY_OPTIONS: QueryOptions = {
  ...QUERY_OPTIONS_COMMON,
  queryParams: {
    ...QUERY_OPTIONS_COMMON.queryParams,
    where: "IsEmergency=1",
  },
};
